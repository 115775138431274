import UtilsEntityView from "./entityView";
import Utils from "./index";

export default class UtilsEntity {
    static get view() {
        return UtilsEntityView
    }

    static async getAll(entity: string, parent_entity: any = null, filter = "", params = {}) {
        await Utils.showLoader();
        let url = `/api/v1/${entity}/`
        if (parent_entity) {
            url = `/api/v1/${parent_entity}/${entity}/`
        }
        const r = await UtilsEntity.request(`${url}?jsfilter=${btoa(filter)}`, 'GET')
        await Utils.hideLoader();
        return r;
    }

    static async getMultiple(ids: any[], entity: string) {
        let url = `/api/v1/${entity}/multiple`
        const r = await UtilsEntity.request(url, 'POST', {
            ids
        })
        return r;
    }

    static async get(id: any, entity: string, parent_id = null, parent_entity = null, hide_loader = false) {
        if (!hide_loader) {
            await Utils.showLoader();
        }
        let url = `/api/v1/${entity}/${id}`
        if (parent_entity) {
            url = `/api/v1/${parent_entity}/${parent_id}/${entity}/${id}`
        }
        const r = await UtilsEntity.request(url, 'GET')
        if (!hide_loader) {
            await Utils.hideLoader();
        }
        return r;
    }

    static async destroy(id: any, entity: string, parent_id = null, parent_entity = null) {

        await Utils.showLoader();
        let url = `/api/v1/${entity}/${id}`
        if (parent_entity) {
            url = `/api/v1/${parent_entity}/${parent_id}/${entity}/${id}`
        }
        const r = await UtilsEntity.request(url, 'DELETE')
        await Utils.hideLoader();
        return r
    }

    static async upsert(data: any, entity: string, parent_id = null, parent_entity = null) {
        let url = `/api/v1/${entity}`
        let method = 'POST'
        if (parent_entity) {
            url = `/api/v1/${parent_entity}/${parent_id}/${entity}`
        }
        if (data.uuid) {
            url = `${url}/${data.uuid}`
            method = 'PATCH'
        }
        let body = {}
        if (data instanceof FormData) {
            body = data
        } else {
            //@ts-ignore
            body[entity] = data
        }
        return await UtilsEntity.request(url, method, body, data instanceof FormData)
    }


    static async request(url: string, method: string, body = {}, plaintext = false) {
        const init = {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        if (method !== "GET" && method !== "DELETE") {
            if (!plaintext) {
                // @ts-ignore
                init.body = JSON.stringify(body)
            } else {
                // @ts-ignore
                init.body = body
            }
        }
        const r = await fetch(url, init)
        return await r.json()
    }
}