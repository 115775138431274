import UtilsErp from "./erp";

export default class UtilsWMS {

    static async startPicking(pickList: IWMSPickList, warehouseUuid: string) {
        let url = `/api/v1/wms/actions/start`
        return await UtilsErp.request(url, "POST", {
            items: pickList,
            warehouse_uuid: warehouseUuid
        })
    }
    static async generatePackages(map: IWMSOrderMap) {
        let url = `/api/v1/wms/actions/generate_packages`
        return await UtilsErp.request(url, "POST", {
            map
        })
    }
}