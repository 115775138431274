import UtilsEntity from "./entity";
import UtilsErp from "./erp";
import UtilsTimeTrack from "./timetrack";
import UtilsWMS from "./wms";

export default class Utils {
    static get entity() {
        return UtilsEntity
    }

    static get erp() {
        return UtilsErp
    }

    static get wms() {
        return UtilsWMS
    }

    static get timeTrack() {
        return UtilsTimeTrack
    }

    static getDaysArray = function(s: Date, e: Date) {const a=[];for(const d=new Date(s);d<=new Date(e);d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;};

    static toLocalISOString(date: any) {
        const localDate = new Date(date - date.getTimezoneOffset() * 60000); //offset in milliseconds. Credit https://stackoverflow.com/questions/10830357/javascript-toisostring-ignores-timezone-offset

        // Optionally remove second/millisecond if needed
        localDate.setSeconds(null);
        localDate.setMilliseconds(null);
        return localDate.toISOString().slice(0, -1);
    }

    static fixSerials(serials: any) {
        if (serials.indexOf(" ") !== -1) {
            serials = serials.split(" ")
        } else if (serials.indexOf(";") !== -1) {
            serials = serials.split(";")
        } else if (serials.indexOf(",") !== -1) {
            serials = serials.split(",")
        } else if (serials.indexOf("\n") !== -1) {
            serials = serials.split("\n")
        }
        serials = typeof serials === 'object' ? serials : [serials]
        return serials.filter((n: any) => n)
    }

    static base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    static arrayMove(arr: any, fromIndex: number, toIndex: number) {
        let element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
        return arr;
    }
    static async download(content: any, filename: any) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(content);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }, 0)
    }
    static createAndDownloadBlobFile(body: any, filename: string, extension = 'pdf') {
        const blob = new Blob([body]);
        const fileName = `${filename}.${extension}`;
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    static async getBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        })
    }

    static async createSelects(data: any, onFilterRequest = (elem: any) => {return {}}, onFilterResults = (data: any, entity: any, element: any) => {return data}, onCreateNewElement = (data: any, entity: any) => {return {}}, selector = ".content-wrapper .select2") {
        document.querySelectorAll(selector).forEach((element) => {
            const entity = element.getAttribute("data-entity")

            let parent = document.body;
            const offcanvasParent: any = element.closest(".offcanvas-body");
            const modalParent: any = element.closest(".modal-body");
            const cardParent: any = element.closest(".card-body");
            if (modalParent) {
                parent = modalParent
            } else if (offcanvasParent) {
                parent = offcanvasParent
            } else if (cardParent) {
                parent = cardParent
            }

            if (entity) {
                let allow_create = false;
                let allow_empty = false;
                let selected_key = null;
                let selected_value = null;

                let name_path: string | null = null;
                let id_path: string | null = null;
                if (element.getAttribute("data-allow-create")) {
                    allow_create = !!element.getAttribute("data-allow-create")
                }
                if (element.getAttribute("data-allow-empty")) {
                    allow_empty = !!element.getAttribute("data-allow-empty")
                }
                if (element.getAttribute("data-selected-key")) {
                    selected_key = Utils.getValueByPath(data, element.getAttribute("data-selected-key"))
                }
                if (element.getAttribute("data-selected-value")) {
                    selected_value = Utils.getValueByPath(data, element.getAttribute("data-selected-value"))
                }
                if (element.getAttribute("data-name-path")) {
                    name_path = element.getAttribute("data-name-path")
                }
                if (element.getAttribute("data-id-path")) {
                    id_path = element.getAttribute("data-id-path")
                }
                if (element.getAttribute("data-selected-key-plain")) {
                    selected_key = element.getAttribute("data-selected-key-plain")
                }
                if (element.getAttribute("data-selected-value-plain")) {
                    selected_value = element.getAttribute("data-selected-value-plain")
                }
                if (selected_key && selected_value) {
                    element.innerHTML = `<option value='${selected_key}' selected>${selected_value}</option>`
                }
                // @ts-ignore
                jQuery(element).wrap('<div class="position-relative"></div>').select2({
                    dropdownParent: parent,
                    placeholder: "Choose option..",
                    allowClear: allow_empty,
                    tags: allow_create,
                    ajax: {
                        url: `/api/v1/${entity}`,
                        data:  (params: any) => {
                            return {
                                search: params.term,
                                ...onFilterRequest(element)
                            }
                        },
                        complete: (xhr, status) => {
                            jQuery(element).trigger("select2:ajax_done", [xhr.responseJSON])
                        },
                        processResults: (data: any) => {
                            let r = onFilterResults(data.data, entity, element).map((d: any) => {
                                return {text: name_path ? Utils.getValueByPath(d, name_path): d.name, id: id_path ? Utils.getValueByPath(d, id_path): d.uuid, data: d}
                            });
                            console.log("RRR", r)
                            return {
                                results: r
                            };
                        }
                    },
                    createTag: (params) => {
                        if (!allow_create) {
                            return null;
                        } else {
                            return {
                                id: params.term,
                                text: `[Neu]${params.term}`,
                                isNew : true
                            };
                        }
                    }
                });
                //@ts-ignore
                jQuery(element).on("select2:select", async (e) => {
                    console.log("eh", e.params.data)
                    //@ts-ignore
                    if (e.params.data.isNew) {
                        const r = await UtilsEntity.upsert({
                            //@ts-ignore
                            name: e.params.data.id,
                            ...onCreateNewElement({name: e.params.data.id}, entity)
                        }, entity);
                        jQuery(element).find('[value="' + e.params.data.id + '"]').replaceWith('<option selected value="' + r.data.id + '">' + r.data.name + '</option>');

                    }
                });
            } else {
                jQuery(element).wrap('<div class="position-relative"></div>').select2({
                    dropdownParent: parent,
                    placeholder: "Choose option..",
                });
            }
        })
    }

    static getValueByPath(data: any, path: any) {
        const s = path.split(".");
        let currentValue = data;
        for (const part of s) {
            if(currentValue[part]) {
                currentValue = currentValue[part]
            } else {
                return null;
            }
        }
        return currentValue;
    }

    static async updateElements(data: any, prefix = '', parent: any = document) {
        if (!Array.isArray(data) && data) {
            Object.keys(data).forEach((column) => {
                const fullName = `${prefix}${column}`
                const value = data[column];
                if (typeof value === "object") {
                    if (Array.isArray(value)) {
                        value.forEach((v: any, i: number) => {
                            Utils.updateElements(v, `${fullName}[${i}].`)
                        })
                    } else {
                        Utils.updateElements(value, `${fullName}.`)
                    }
                    Utils.setElement(fullName, value, parent)
                } else {
                    Utils.setElement(fullName, value, parent)
                }
            })
        }
    }
    static setElement(key: string, value:string, parent: any = document) {
        //@ts-ignore
        parent.querySelectorAll(`*[data-entity-field='${key}']`).forEach((element) => {
            const applyForValue = element.getAttribute("data-entity-value");
            const pattern = element.getAttribute("data-entity-pattern");
            const target = element.getAttribute("data-entity-target");
            const formatter = element.getAttribute("data-entity-formatter");
            if (applyForValue) {
                if (applyForValue === value || applyForValue === `${value}`) {
                    element.classList.remove("d-none");
                } else {
                    element.classList.add("d-none");
                }
            } else if(pattern && target) {
                element.setAttribute(target, pattern.replace("$VAL", value))
            } else {
                if (element.nodeName === "INPUT") {
                    const inputType = element.getAttribute("type");
                    if (inputType === "checkbox") {
                        if (value) {
                            element.setAttribute("checked", "checked")
                        } else {
                            element.removeAttribute("checked")
                        }
                    } else if(inputType === "date") {
                        const s = value.split(".")
                        const newVal = `${s[2]}-${s[1]}-${s[0]}`
                        // @ts-ignore
                        element.value = formatter ? Utils[formatter](newVal) : newVal;
                    } else {
                        // @ts-ignore
                        element.value = formatter ? Utils[formatter](value) : value;
                    }
                } else if(element.nodeName === "SELECT" || element.nodeName === "TEXTAREA") {
                    // @ts-ignore
                    element.value = formatter ? Utils[formatter](value) : value;
                } else {
                    // @ts-ignore
                    element.innerHTML = formatter ? Utils[formatter](value) : value;
                }
            }
        })
    }

    static formatDate(d: any) {
        return new Date(Date.parse(d)).toLocaleString("de-DE", {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        })
    }

    static translate(str: string) {
        const parts = str.split(".")
        // @ts-ignore
        let o = window.I18n
        parts.forEach((part) => {
            // @ts-ignore
            if (o[part]) {
                // @ts-ignore
                o = o[part]
            } else {
                console.warn(`Translation not found: ${str}`)
                return `Translation not found: ${str}`;
            }
        })
        if (typeof(o) === "string") {
            return o;
        } else {
            return "";
        }
    }
    static msToHMS( ms: number ) {
        let seconds: any = ms / 1000;
        let hours: any = parseInt( String(seconds / 3600) ); // 3,600 seconds in 1 hour
        seconds = seconds % 3600; // seconds remaining after extracting hours
        let minutes: any = parseInt( String(seconds / 60) ); // 60 seconds in 1 minute
        seconds = parseInt(String(seconds % 60));
        if (hours <= 9) {
            hours = `0${hours}`
        }
        if (minutes <= 9) {
            minutes = `0${minutes}`
        }
        if (seconds <= 9) {
            seconds = `0${seconds}`
        }
        return hours+":"+minutes+":"+seconds;
    }

    static async hideLoader() {
        const loader = document.querySelector("#loading-bg");
        document.body.classList.remove("is-loading");
        if(loader) {
            loader.classList.add("d-none");
        }
    }
    static async showLoader() {
        const loader = document.querySelector("#loading-bg");
        document.body.classList.add("is-loading");
        if(loader) {
            loader.classList.remove("d-none");
        }
    }
    static genRandomColor() {
        return Math.floor(Math.random()*16777215).toString(16);
    }

    static genCalendarColor(n: number) {
        const colors: any[] = [
            '049CE5',
            'E77C73',
            '7CB342',
            'D71A60',
            '616161',
            '8E25AA',
            'E4C442',
            'B39DDB',
            '0A8043'
        ];

        return colors[n] ? colors[n] : Utils.genRandomColor();
    }

    static makeId(length = 7) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    static secondsToHhMm(seconds: number) {
        try {
            return new Date(Math.abs(seconds) * 1000).toISOString().slice(11, 16);
        } catch (error) {
            return "00:00"
        }
    }
    static secondsToHhMmSs(seconds: number) {
        try {
            return new Date(Math.abs(seconds) * 1000).toISOString().slice(11, 19);
        } catch (error) {
            return "00:00:00"
        }
    }
}